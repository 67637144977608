<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="mt-4" novalidate @submit.prevent="handleSubmit(onSubmit)">
      <ValidationProvider
        vid="username"
        name="username"
        rules="required"
        v-slot="{ errors }"
      >
        <div class="form-group">
          <label for="usernameInput">{{ $t("global.userName") }}</label>
          <input
            type="username"
            :class="
              'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')
            "
            id="usernameInput"
            aria-describedby="usernameHelp"
            v-model="user.username"
            :placeholder="$t('global.userName')"
            required
          />
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <ValidationProvider
        vid="password"
        name="Password"
        rules="required"
        v-slot="{ errors }"
      >
        <div class="form-group">
          <label for="passwordInput">{{ $t("global.password") }}</label>
          <!-- <router-link to="/auth/password-reset1" class="float-right">
            {{ $t('auth.forgotPassword') }}
          </router-link> -->
          <input
            type="password"
            :class="
              'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')
            "
            id="passwordInput"
            v-model="user.password"
            :placeholder="$t('global.password')"
            required
          />
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <div class="d-inline-block w-100">
        <div class="custom-control custom-checkbox d-inline-block mt-2 pt-1">
          <input type="checkbox" class="custom-control-input" id="rememberMe" />
          <label class="custom-control-label" for="rememberMe">{{
            $t("auth.rememberMe")
          }}</label>
        </div>
        <button
          type="submit"
          class="btn btn-primary float-right"
          :disabled="loading"
        >
          <b-spinner v-if="loading" small></b-spinner>
          <span class="mx-2">{{ $t("auth.signIn") }}</span>
        </button>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import auth from "../../../../services/auth";

export default {
  props: ["username", "password"],
  data: () => ({
    loading: false,
    user: {
      username: "",
      password: "",
    },
  }),
  mounted() {
    this.user.username = this.$props.username;
    this.user.password = this.$props.password;
  },
  methods: {
    onSubmit() {
      this.loading = true;
      auth
        .login(this.user)
        .then((response) => {
          if (response.status) {
            console.log(response.data.data.admin);
            localStorage.setItem("access_token", response.data.data.token);
            localStorage.setItem(
              "user",
              JSON.stringify(response.data.data.admin)
            );
            const permissions = response.data.data.admin.roles
              .map((role) => role.permissions)
              .flat();

            localStorage.setItem("permissions", JSON.stringify(permissions));
            this.$router.push({ name: "dashboard" });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
