import { singleSignOn } from "../axios";

export default {
  login(userData) {
    return singleSignOn().post("/admin/auth/signin", {
      ...userData,
      always: true,
    });
  },
  register(userData) {
    return singleSignOn().post("/admin/signup", userData);
  },
  getUserdetails(token = "") {
    return singleSignOn(token).get("/admin/auth/whoami");
  },
};
