<template>
  <div class="auth-inputs">
    <div class="d-flex justify-content-between">
      <h1 class="mb-0">{{ $t("auth.signIn") }}</h1>

      <b-dropdown no-caret variant="primary">
        <template v-slot:button-content>
          <div class="iq-waves-effect language-title">
            <img
              :src="selectedLang.image"
              alt="img-flaf"
              class="img-fluid"
              :class="selectedLang.direction === 'rtl' ? 'ml-2' : 'mr-2'"
            />
            <span>{{ selectedLang.title }}</span>
            <i class="ri-arrow-down-s-line"></i>
          </div>
        </template>
        <b-dropdown-item
          v-for="(lang, i) in langsOptions"
          :key="`Lang${i}`"
          @click="langChange(lang)"
          class="gap-x-2"
        >
          <img
            :src="lang.image"
            alt="img-flaf"
            class="img-fluid"
            :class="selectedLang.direction === 'rtl' ? 'ml-2' : 'mr-2'"
          />
          {{ lang.title }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <tab-content id="pills-tabContent-1" class="mt-0">
      <tab-content-item
        :active="true"
        id="pills-jwt-fill"
        aria-labelled-by="pills-jwt-tab-fill"
      >
        <sign-in1-form username="" password=""></sign-in1-form>
      </tab-content-item>
    </tab-content>
  </div>
</template>

<script>
import SignIn1Form from "./Forms/SignIn1Form";
import langChange from "@/Mixins/langchange";
import { getLanguage } from "@/Utils/helper";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "SignIn1",
  components: { SignIn1Form },
  data() {
    return {
      selectedLang: getLanguage(),
      rtl: false,
    };
  },
  mixins: [langChange],
  methods: {
    ...mapActions({
      langChangeState: "Setting/setLangAction",
    }),
  },
  computed: {
    ...mapGetters({
      langsOptions: "Setting/langOptionState",
    }),
  },
  mounted() {
    const loggedIn = localStorage.getItem("access_token");
    if (loggedIn !== undefined && loggedIn !== null) {
      this.$router.push({ name: "dashboard" });
    }
  },
};
</script>

<style lang="scss" >
[dir="rtl"] .auth-inputs {
  .dropdown-menu {
    right: 0 !important;
  }
}
</style>
